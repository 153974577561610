import React from "react";
const styles = {
  rtl: {
    direction: "rtl",
  } as React.CSSProperties,
  loanContainer: {
    display: "flex",
    flexDirection: "column",
    direction: "rtl",
  } as React.CSSProperties,
};

export default styles;

import React, { useState, useEffect } from 'react';
import { Container, Button, Form, Row, Col, Modal, InputGroup } from 'react-bootstrap';
import { getLocaleWiseLabel as t } from '../../../lib/localeUtils';
import styles from './loan-form.styles';
import { navigate } from 'gatsby';
import axios from 'axios';
import clientConfig from '../../../../client-config';
import hebrewTexts from '../commonHebrewTexts';

const shekel = require('./assets/shekel_icon.png');

function LoanForm(props: any) {
  let defaultValueForLoanPurpose = '';
  let defaultValueForLoanType = '';

  if (props.dropDownDefault) {
    if (props.dropDownDefault.btnClicked == 'bridgingOrShortTermLoans') {
      defaultValueForLoanPurpose = t(props.labelArr, 'lpo_fundingImport', 'heb');
      defaultValueForLoanType = t(props.labelArr, 'lto_bullit', 'heb');
    }
    if (props.dropDownDefault.btnClicked == 'mediumOrLongTermLoan') {
      defaultValueForLoanPurpose = t(props.labelArr, 'lpo_inventoryPurchase', 'heb');
      defaultValueForLoanType = t(props.labelArr, 'lto_spitzer', 'heb');
      console.log(defaultValueForLoanPurpose, defaultValueForLoanType);
    }
    if (props.dropDownDefault.btnClicked == 'fundingInvoicesOrChecks') {
      defaultValueForLoanPurpose = t(props.labelArr, 'lpo_specificDelayedInvoiceOrCheckFinance', 'heb');
      defaultValueForLoanType = t(props.labelArr, 'lto_bullit', 'heb');
    }
  }

  const [show, setShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState(['']);
  const [loanAmount, setLoanAmount] = useState('50,000');
  const [registrarCompaniesId, setRegistrarCompaniesId] = useState(0);
  const [loanPurpose, setloanPurpose] = useState(defaultValueForLoanPurpose);
  const [loanType, setLoanType] = useState(defaultValueForLoanType);
  const [companyName, setCompanyName] = useState('');
  const [showOtherInput, setShowOtherInput] = useState(false);
  const { labelArr } = props;
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  var submitHandler = () => {
    let validInputs: boolean = false;
    let errorMessage: string[] = [];
    if (loanPurpose === '' || loanPurpose === 'other') {
      errorMessage.push(t(labelArr, 'loanPurpose_InputText', 'heb'));
      validInputs = true;
    }
    if (!(loanType.length > 0)) {
      errorMessage.push(t(labelArr, 'loanType_InputText', 'heb'));
      validInputs = true;
    }
    if (!(parseInt(loanAmount) > 0)) {
      errorMessage.push(t(labelArr, 'loanAmount_InputText', 'heb'));
      validInputs = true;
    }
    if (!(registrarCompaniesId > 0)) {
      errorMessage.push(t(labelArr, 'registrarCompaniesId_InputText', 'heb'));
      validInputs = true;
    }
    if (!(companyName.length > 0)) {
      errorMessage.push(t(labelArr, 'companyName_InputText', 'heb'));
      validInputs = true;
    }
    if (errorMessage.length > 0) {
      setErrorMessage(errorMessage);
      handleShow();
    } else {
      let loanDetails = {
        amount: parseInt(loanAmount.replace(/,/g, '')),
        registeredCompanyId: registrarCompaniesId,
        purpose: loanPurpose,
        type: loanType,
        companyName: companyName,
      };
      localStorage.setItem('loanDetails', JSON.stringify(loanDetails));

      //send to slack
      axios.post(`${clientConfig.backend}/api/v1/SlackLog`, {
        currentPage: '/LoanPage',
        details: loanDetails,
      });
      navigate('/UserDetailsPage');
    }
  };
  var handleChange = (name: string, e: any) => {
    if (e.target.value > 0) {
      if (name === 'loanAmount') setLoanAmount(e.target.value);
      if (name === 'registrarCompaniesId') setRegistrarCompaniesId(e.target.value);
    }
  };

  return (
    <Container style={styles.loanContainer}>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body style={{ fontSize: '100%' }} className='text-right'>
          <h6> {t(labelArr, 'FollowingRequiredFields', 'heb')} </h6>
          {errorMessage.map((value) => {
            return (
              <div className='text-right' key={value}>
                {value}
              </div>
            );
          })}
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            {hebrewTexts.close}
          </Button>
        </Modal.Footer>
      </Modal>
      <h1 className='text-center' style={{ marginBottom: '5%' }}>
        {t(labelArr, 'loanPage_Title', 'heb')}
      </h1>
      <Form.Group as={Row}>
        <Form.Label column className='text-right' sm='2'>
          {t(labelArr, 'loanPurpose_InputText', 'heb')}
        </Form.Label>
        <Col sm='4'>
          <Form.Control
            className='text-right'
            as='select'
            onChange={(e) => {
              let show = e.target.value === 'other' ? true : false;
              setShowOtherInput(show);
              setloanPurpose(e.target.value);
            }}
            defaultValue={defaultValueForLoanPurpose}
          >
            <option value=''>----בחר----</option>
            <option value={t(labelArr, 'lpo_fundingImport', 'heb')}>{t(labelArr, 'lpo_fundingImport', 'heb')}</option>
            <option value={t(labelArr, 'lpo_generalGrowth', 'heb')}>{t(labelArr, 'lpo_generalGrowth', 'heb')}</option>
            <option value={t(labelArr, 'lpo_inventoryPurchase', 'heb')}>
              {t(labelArr, 'lpo_inventoryPurchase', 'heb')}
            </option>
            <option value={t(labelArr, 'lpo_machineryPurchase', 'heb')}>
              {t(labelArr, 'lpo_machineryPurchase', 'heb')}
            </option>
            <option value={t(labelArr, 'lpo_specificDelayedInvoiceOrCheckFinance', 'heb')}>
              {t(labelArr, 'lpo_specificDelayedInvoiceOrCheckFinance', 'heb')}
            </option>
            <option value='other'>{t(labelArr, 'lpo_other', 'heb')}</option>
          </Form.Control>
        </Col>
        <Col sm='4'>
          {showOtherInput ? (
            <Form.Control
              type='text'
              onChange={(e) => {
                setloanPurpose(e.target.value);
              }}
            />
          ) : null}
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label className='text-right' column sm='2'>
          {t(labelArr, 'loanType_InputText', 'heb')}
        </Form.Label>
        <Col sm='4'>
          <Form.Control
            onChange={(e) => {
              setLoanType(e.target.value);
            }}
            as='select'
            defaultValue={defaultValueForLoanType}
          >
            <option value=''>----בחר----</option>
            <option value={t(labelArr, 'lto_spitzer', 'heb')}>{t(labelArr, 'lto_spitzer', 'heb')}</option>
            <option value={t(labelArr, 'lto_bullit', 'heb')}>{t(labelArr, 'lto_bullit', 'heb')}</option>
            <option value={t(labelArr, 'lto_baloon', 'heb')}>{t(labelArr, 'lto_baloon', 'heb')}</option>
          </Form.Control>
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label className='text-right' column sm='2'>
          {t(labelArr, 'loanAmount_InputText', 'heb')}
        </Form.Label>
        <Col sm='4'>
          <InputGroup className='mb-3'>
            <InputGroup.Append>
              <InputGroup.Text id='basic-addon2'>
                <img src={shekel} width={20} alt='' />
              </InputGroup.Text>
            </InputGroup.Append>
            <Form.Control
              min={0}
              onChange={(e) => {
                //  handleChange("loanAmount", e);
                let x = new Intl.NumberFormat().format(parseInt(e.target.value.replace(/,/g, '')));
                if (x === 'NaN') {
                  x = '0';
                }
                setLoanAmount(x);
              }}
              value={loanAmount ? loanAmount : ''}
              className='text-right'
              // type="number"
            />
          </InputGroup>
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label className='text-right' column sm='2'>
          {t(labelArr, 'registrarCompaniesId_InputText', 'heb')}
        </Form.Label>
        <Col sm='4'>
          <Form.Control
            onChange={(e) => {
              handleChange('registrarCompaniesId', e);
            }}
            value={registrarCompaniesId ? registrarCompaniesId : ''}
            className='text-right'
            type='number'
            placeholder=''
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label className='text-right' column sm='2'>
          {t(labelArr, 'companyName_InputText', 'heb')}
        </Form.Label>
        <Col sm='4'>
          <Form.Control
            value={companyName ? companyName : ''}
            onChange={(e) => {
              setCompanyName(e.target.value);
            }}
            className='text-right'
            type='text'
          />
        </Col>
      </Form.Group>

      <Button
        onClick={submitHandler}
        style={{ alignSelf: 'center', marginTop: '50px' }}
        variant='primary'
        type='submit'
      >
        {t(labelArr, 'continue_Button', 'heb') + ' >'}
      </Button>
    </Container>
  );
}

export default LoanForm;
